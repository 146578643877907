import { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useStripe } from '@stripe/react-stripe-js'

import { ReactComponent as PregnancyIcon } from 'assets/icons/pregnancy-icon.svg'
import { ReactComponent as GymIcon } from 'assets/icons/gym-icon.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/at-home-icon.svg'
import { ReactComponent as BothIcon } from 'assets/icons/gym-and-home-icons.svg'

import Heart from 'assets/icons/blackheart.png'
import Weights from 'assets/icons/weightsblack.png'
import Calendar from 'assets/icons/calendarblack.png'

import { ReactComponent as CheckMark } from 'assets/icons/green-check.svg'

import { ValidGuideSelections, ValidPlanSelections } from 'types'

import { internalStripeApi, MergedProductAndPrice, Price } from 'api/stripe'
import routes from 'constants/routes'
import { ENV } from 'constants/environment'
import { truncateErrorString } from 'core/error'
import LocalStorage from 'core/localStorage'
import Modal from 'common/Modal'
import Input from 'common/inputs/Input'
import { useUserContext } from 'context/user.context'

import SubscriptionSelectionForm, {
  SubscriptionChoices,
} from './SubscriptionSelectionForm'
import SubscriptionSelectionModalForm from './SubscriptionSelectionModalForm'

export default function SubscriptionSelection() {
  // prettier-ignore
  const [subscriptionChoice, setSubscriptionChoice] = useState<SubscriptionChoices>(null)
  const [products, setProducts] = useState([] as MergedProductAndPrice[])
  const [prices, setPrices] = useState([] as Price[])
  const [showModal, setShowModal] = useState(false)
  const [couponCode, setCouponCode] = useState<undefined | string>(undefined)

  const stripe = useStripe()
  const history = useHistory()
  const [user] = useUserContext()

  const guideSelection = LocalStorage.get('guideSelection')
  if (!guideSelection) return <Redirect to={routes.GUIDE_SELECTION} />
  const isPregnancy = guideSelection === 'pregnancy'

  const planSelection = LocalStorage.get('planSelection')
  if (!planSelection) return <Redirect to={routes.PLAN_SELECTION} />

  useEffect(() => {
    async function fetchData() {
      const response = await internalStripeApi.getProducts()
      if (response.success) {
        const newProducts = response.data.map(product => {
          const prices = product.prices.filter(price => price.active)
          return { ...product, prices }
        })

        setProducts(newProducts)

        const planMapper = {
          'gym-and-home': '- Gym and At-Home Plan',
          gym: '- Gym Plan',
          home: '- At-Home Plan',
        }

        const product = response.data.find(
          ({ name, active }) =>
            name.startsWith(isPregnancy ? 'Pregnancy' : 'Regular') &&
            name.includes(planMapper[planSelection!]) &&
            active,
        )!

        const prices = product.prices.filter(price => price.active)

        setPrices(prices)
      } else {
        const msg = truncateErrorString(
          response.data.response?.data ??
          'There was an error retrieving products',
        )

        toast.error(msg)
      }
    }

    fetchData()
  }, [isPregnancy, planSelection, subscriptionChoice])

  async function handleSubmit() {
    if (isPregnancy) {
      setShowModal(true)
    } else {
      goToCheckout()
    }
  }

  async function goToCheckout() {
    const product = products.find(
      ({ name }) =>
        name.startsWith(isPregnancy ? 'Pregnancy' : 'Regular') &&
        name.includes(
          {
            'gym-and-home': '- Gym and At-Home Plan',
            gym: '- Gym Plan',
            home: '- At-Home Plan',
          }[planSelection!],
        ),
    )!

    const price = product.prices.find(
      price => price.recurring.interval === subscriptionChoice && price.active,
    )!

    // make api call to backend to create session
    const response = await internalStripeApi.createCheckoutSession({
      price_id: price.id,
      success_url: ENV.APP_URL + routes.SUBSCRIPTION_FINALIZATION,
      cancel_url: ENV.APP_URL + routes.SUBSCRIPTION_SELECTION,
      email: user.email,
      coupon_code: couponCode,
      client_reference_id: getClientReferenceId(),
    })

    if (response.success) {
      LocalStorage.set('checkout_session_token', response.data.session_id)
      try {
        await stripe?.redirectToCheckout({
          sessionId: response.data.session_id,
        })
      } catch (error: any) {
        toast.error(truncateErrorString(error.message))
      }
    } else {
      const msg = truncateErrorString(
        response.data.response?.data ??
        'An error occurred while creating your Stripe Checkout Session',
      )

      toast.error(msg)
    }
  }

  function getClientReferenceId() {
    return (
      ((window as any).Rewardful && (window as any).Rewardful.referral) ||
      'checkout_' + new Date().getTime()
    )
  }

  function handleGoBack() {
    history.push(routes.PLAN_SELECTION)
  }

  const LeftPanelIcon = {
    'gym-and-home': <BothIcon />,
    gym: <GymIcon width={64} height={64} />,
    home: <HomeIcon />,
  }[planSelection!]

  const planSelectionStr = {
    gym: 'Gym',
    home: 'At-Home',
    'gym-and-home': 'At-Home + Gym',
  }[planSelection!]

  const goods = goodsObj[planSelection!][guideSelection!]
  const pros = prosObj[planSelection!][guideSelection!]

  const goodsItems = goods.map(good => {
    return (
      <div key={good} className={`flex mb-5`}>
        <CheckMark className="flex-shrink-0 mr-8 ml-3 mt-2" />
        <p className="font-semibold text-left">{good}</p>
      </div>
    )
  })

  const prosItems = pros.map(pro => {
    return (
      <div className="flex" key={pro.text}>
        <img
          src={pro.icon}
          className="mr-5 mt-1"
          style={{ maxWidth: '23px', maxHeight: '20px' }}
        />
        <p className="text-xl pb-5">{pro.text}</p>
      </div>
    )
  })

  return (
    <main className="flex flex-col items-center">
      <section
        className={`flex md:flex-row flex-col justify-center xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 mt-10 text-center rounded-3xl text-gray-700 ${isPregnancy ? 'bg-light-blue' : 'bg-peach'
          }`}
      >
        <div
          data-name="left-panel"
          className="flex flex-col items-center items-between md:w-1/2 w-full pl-4 pt-6"
        >
          {isPregnancy && <PregnancyIcon />}
          {!isPregnancy && LeftPanelIcon}
          <h1 className="text-3xl font-semibold mt-10 mb-4 text-lg">
            {isPregnancy ? 'Pregnancy' : 'Regular'} Guide
          </h1>
          <h3 className="font-semibold mb-3 text-xl">
            {planSelectionStr} Guide
          </h3>
          <h2 className="text-center font-semibold text-2xl mb-4 mt-12">
            The Goods
          </h2>
          <div className="flex flex-col pr-6">{goodsItems}</div>
        </div>
        <div
          data-name="right-panel"
          className="md:w-1/2 w-full flex flex-col justify-between"
        >
          <div
            data-name="top"
            className="text-left flex flex-col justify-center h-full w-full bg-right bg-no-repeat bg-opacity-5 rounded-t-3xl"
          >
            <div className="ml-5 mt-10 mb-8">{prosItems}</div>
          </div>
          <SubscriptionSelectionForm
            onSubmit={handleSubmit}
            setSubscriptionChoice={setSubscriptionChoice}
            subscriptionChoice={subscriptionChoice}
            isPregnancy={isPregnancy}
            onGoBack={handleGoBack}
            prices={prices}
          />
        </div>
      </section>
      <div className="md:w-1/3 w-10/12">
        <Input
          className={couponCode ? 'font-bold' : ''}
          name="coupon-code"
          value={couponCode}
          onChange={e => setCouponCode(e.target.value)}
          placeholder="Coupon"
        />
      </div>
      {showModal && (
        <Modal handleClose={() => setShowModal(false)}>
          <SubscriptionSelectionModalForm next={() => goToCheckout()} />
        </Modal>
      )}
    </main>
  )
}

type GoodsObj = {
  [type in ValidPlanSelections]: {
    [sel in ValidGuideSelections]: string[]
  }
}

const goodsObj = {
  gym: {
    pregnancy: [
      "This training plan will guide you all the way through delivery and into your postpartum healing journey with new pregnancy-safe workouts each week programmed by a pre/postnatal fitness specialist tailored to your exact week of pregnancy.",
      "Gym workouts (5 new ~1 hour workouts each week) are programmed for you with step-by-step instructions and video demos of each exercise.",
      "Higher level intensity throughout.",
    ],
    regular: [
      'With this plan, 5 new and challenging workouts focused on progressive overload pull into the app every week giving you a total body split. Currently, the split is legs/glutes, push (chest/triceps/shoulders), total body/conditioning, legs/glutes + core, and pull (biceps/back).',
      'All workouts are programmed for you with step-by-step instructions and video demos for each exercise. Go at your own pace with easy-to-follow instructions.',
      'A recommended schedule given to you each week to ensure optimal results.',
      'Easy-to-follow instructions.',
    ],
  },
  home: {
    pregnancy: [
      "This training plan will guide you all the way through delivery and into your postpartum healing journey with new pregnancy-safe workouts each week programmed by a pre/postnatal fitness specialist tailored to your exact week of pregnancy.",
      "At-home workouts are in both video and written form. Press “play” and do them with Meg from start to finish with minimal equipment, or on your own time with written steps and demo videos. Your sweet baby bump will grow right alongside hers the entire guide through.",
      "At-home workouts are challenging and fun (the follow-along videos are all 25 minutes or less)! Moderate intensity level that varies throughout.",
    ],
    regular: [
      "Challenging workouts with minimal equipment: 5# and 10# dumbbells, a heavy resistance band, a chair/step/bents. That's all you need! If you have heavier weights at home, integrate them in! Trust us, you're going to love these workouts and Meg makes them so fun, creative, and sweaty.",
      "With this plan, 3 new at-home workouts (20-40 min.) pull into the app each week. Don't think it's enough? Remember you still have access to the previous two weeks worth of workouts (9 total). The Wilma community has loved this format over the years. Honestly, it's our secret sauce. It allows you the flexibility you need while still giving you plenty to choose from. Plus, you have yoga and high Fitness options each week too!",
      "All at-home workouts are in both video and written form. Press “play” and do them with Meg from start to finish with minimal equipment, or on your own time, and from anywhere!",
      "A recommended schedule given to you each week to ensure optimal results.",
    ],
  },
  'gym-and-home': {
    pregnancy: [
      'This training plan will guide you all the way through delivery and into your postpartum healing journey with new pregnancy-safe workouts each week programmed by a pre/postnatal fitness specialist tailored to your exact week of pregnancy.',
      '6-week follow along Postpartum / Diastasis Recti recovery guide included.',
      "Access to Meg’s pregnancy tips and motivation along the way to help you feel your strongest through  pregnancy, delivery, and recovery.",
      "Pregnancy-safe additional 5-minute workouts when you're short on time.",
      "Delivery prep videos in the final month of pregnancy. Access to “The Milk Mama”: a beautiful cookbook with 52 healthy location recipes for breakfast breastfeeding moms.",
      "A place to track progress and check off workouts to keep you motivated.",
    ],
    regular: [
      "Access to Meg's full training plan.",
      'With this plan, 3 new at-home and 5 new gym workouts pull into the app every single week for a total body split.',
      'All at-home workouts are in both video and written form. Press “play” and do them with Meg from start to finish with minimal equipment, or on your own time. All gym workouts focused on progressive overload are programmed for you with step-by-step instructions and video demos of each exercise.',
      'At home follow-along workouts are 20-40 minutes in length. Gym workouts are ~1 hour each.',
      'We get it! You’re busy doing amazing things! Every week one ‘Wilma Whip’ drops into the app for both home and gym. These are workouts programmed for half the length of time so you have a fun and effective option on busier days. 10-15 minutes for a home workout and 30 minutes for the gym. You’ll love them!',
      'A recommended schedule given to you each week to ensure optimal results.',
    ],
  },
} as GoodsObj

type ProsObj = {
  [type in ValidPlanSelections]: {
    [sel in ValidGuideSelections]: {
      icon: string
      text: string
    }[]
  }
}

const prosObj = {
  gym: {
    pregnancy: [
      { icon: Weights, text: 'Easy to follow gym workouts' },
      { icon: Calendar, text: 'New workouts every week' },
      { icon: Heart, text: 'Go at your own pace' },
    ],
    regular: [
      { icon: Weights, text: 'Easy to follow gym workouts' },
      { icon: Calendar, text: 'New workouts every week' },
      { icon: Heart, text: 'Go at your own pace' },
    ],
  },
  home: {
    pregnancy: [
      { icon: Heart, text: 'Follow along at-home workouts' },
      { icon: Calendar, text: 'New workouts every week' },
      { icon: Weights, text: 'Minimal Equipment' },
    ],
    regular: [
      { icon: Heart, text: 'Follow along at-home workouts' },
      { icon: Calendar, text: 'New workouts every week' },
      { icon: Weights, text: 'Minimal Equipment' },
    ],
  },
  'gym-and-home': {
    pregnancy: [
      { icon: Weights, text: 'Easy to follow gym workouts' },
      { icon: Calendar, text: 'Follow along at-home workouts' },
      { icon: Heart, text: 'Workout any time, anywhere' },
    ],
    regular: [
      { icon: Weights, text: 'Easy to follow gym workouts' },
      { icon: Calendar, text: 'Follow along at-home workouts' },
      { icon: Heart, text: 'Workout any time, anywhere' },
    ],
  },
} as ProsObj
