import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'

import {userApi} from 'api/user'
import {internalStripeApi} from 'api/stripe'
import routes from 'constants/routes'
import LocalStorage from 'core/localStorage'
import LoadingPage from 'components/authentication/LoadingPage'
import {truncateErrorString} from 'core/error'
import {useUserContext} from 'context/user.context'

export default function SubscriptionFinalization() {
  const history = useHistory()
  const [, userDispatch] = useUserContext()

  useEffect(() => {
    async function setCustomerId() {
      const session_token = LocalStorage.get('checkout_session_token')
      if (session_token) {
        LocalStorage.set('checkout_session_token', null)
        const response = await internalStripeApi.saveUserCustomerId({
          session_id: session_token!,
          due_date: LocalStorage.get('due_date'),
        })
        if (response.success) {
          const userResponse = await userApi.getUser()
          if (userResponse.success) {
            LocalStorage.set('user', userResponse.data)
            userDispatch({type: 'set', data: userResponse.data})
          }
          history.push(routes.ONBOARDING_COMPLETE)
        } else {
          LocalStorage.set('checkout_session_token', session_token)
          const msg = truncateErrorString(
            response.data.response?.data ?? 'Something went wrong...',
          )

          toast.error(msg)
          toast.info('You are being redirected')
          setTimeout(() => history.push(routes.ONBOARDING_COMPLETE), 4000)
        }
      }
    }

    setCustomerId()
  }, [history, userDispatch])

  return <LoadingPage />
}
